import * as React from 'react';
import { Row } from 'components/row/Row';

import s from './CultureBlock.scss';

interface ICultureBlockProps {
  full: boolean;
  heading: React.ReactNode;
  caption: string;
  link: React.ReactNode;
}

export const CultureBlock = ({ heading, caption, link, full}: ICultureBlockProps) => (

    <div className={s.culture_block}>
    <div className={`${s.culture_block__container} ${full ? '__full' : ''}`}>
        <Row>
        <div className={`${s.culture_block__col} `}>
            <h3 className={s.culture_block__heading}>{heading}</h3>
          </div>
        </Row>
        <Row>
          <div className={s.culture_block__col__right}>
            <p className={s.culture_block__caption}>{caption}</p>
            <p className={s.culture_block__link}>{link}</p>
          </div>
        </Row>
        </div>
    </div>
);
