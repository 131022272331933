import * as React from 'react';
import Helmet from 'react-helmet';

import { Link as HeaderLink } from 'components/header/Link';
import { CultureBlock } from 'components/culture-block/CultureBlock';
import { Construction } from 'components/construction/Construction';

export default () => (
  <>
    <Helmet title="Home" />
        <Construction
            message={(
                <>
                    We really appreciate you for taking the time to visit us. You've caught us mid-reconstruction. We're working hard to complete it soon while we take care of client work. Please feel free to <HeaderLink to="mailto:newbusiness@averlast.co" name="get in touch"/>
                </>
            )}
        />

    <CultureBlock
        full={false}
      heading={(
        <>
            <h2>When you win, we all do.</h2>

            <p>Are you a black creative, technologist or entrepreneur?

              Averlast is proudly black owned and it’s an important part of our mission to support black artisans, like you, because your success is a success for our culture.

              In response to an environment which lends itself to limitation and partiality, we are exploring methods to promote equity in information, opportunity, community, and capital.
            </p>

              <h2>That may include things like:</h2>

            <p>
                  Project collaborations & exchanges.
                  Group ideation & concept sessions.
                  Workshops/professional development webinars for junior professionals.
                  Development of fair gig marketplaces.
                  Facilitation/promotion of the creative capital business models.
            </p>

            <h2>Ok so up first</h2>

            <p>

            </p>
        </>
      )}
    />
  </>
);
